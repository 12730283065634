import { Component, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OutletService } from '@data-access/services';
import { Outlet } from '@models/index';
import { MenuItem, PrimeIcons } from 'primeng/api';
import { CardModule } from 'primeng/card';
import { MenubarModule } from 'primeng/menubar';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-outlet-menubar',
  standalone: true,
  imports: [CardModule, MenubarModule],
  templateUrl: './outlet-menubar.component.html',
  styleUrl: './outlet-menubar.component.css'
})
export class OutletMenubarComponent implements OnDestroy {
  outlet: Outlet | undefined = undefined;
  outletId: number = 0;
  menuItems: MenuItem[] = [];
  activeItem: MenuItem | undefined;
  private $destroy = new Subject<void>();

  constructor(private outletService: OutletService, private route: ActivatedRoute) {
    this.outletService.outlet$.pipe(takeUntil(this.$destroy)).subscribe((result) => {
        this.outlet = result as Outlet;
        this.menuItems = this.getMenuItems();
        this.activeItem = this.menuItems[0];
    });

  }

  ngOnDestroy(): void {
    this.$destroy.next();
    this.$destroy.complete();
  }

  getMenuItems(): MenuItem[] {
    return [
      { label: 'Sales', icon: PrimeIcons.SHOPPING_CART, disabled: true, visible: false },
      { label: 'Orders', icon: PrimeIcons.SHOPPING_BAG, routerLink: '/outlet/' + this.outlet?.id + '/orders/' },
      { label: 'Products', icon: PrimeIcons.BOOKMARK, routerLink: '/outlet/' + this.outlet?.id + '/products/' },
      { label: 'Inventory', icon: PrimeIcons.LIST, routerLink: '/outlet/' + this.outlet?.id + '/inventory/' },
      { label: 'Customers', icon: PrimeIcons.USER, routerLink: '/outlet/' + this.outlet?.id + '/customers/' },
      { label: 'Report', icon: PrimeIcons.CHART_PIE, routerLink: '/outlet/' + this.outlet?.id + '/report/' },
      { label: 'Workers', icon: PrimeIcons.USERS, routerLink: '/outlet/' + this.outlet?.id + '/workers/' },
      { label: 'Settings', icon: PrimeIcons.COG, routerLink: '/outlet/' + this.outlet?.id + '/settings/' },
    ];
  }
}
