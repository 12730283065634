import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { KeyValuePipe } from '@angular/common';

@Component({
  selector: 'app-text-input-field',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './text-input-field.component.html',
  styleUrl: './text-input-field.component.css'
})
export class TextInputFieldComponent {
@Input() control : FormControl = new FormControl();
@Input() placeHolder : string = '';
@Input() label : string = '';
@Input() inputId : string = '';
@Input() submitted : boolean = false;
}
