<div class="form_div">
  @if(label !== ""){
    <label [for]="inputId">{{label}}</label>
  }
    <input
      class="p-3 border rounded-md"
      [placeholder]="placeHolder"
      [formControl]="control"
      type="email"
      [id]="inputId"
    />
    @if(control.invalid && control.dirty && control.touched){
      <!-- control.markAsDirty(); -->
      <div class="flex flex-col items-start text-xs italic text-gray-600">
        @if(control.errors?.['required']){
          <span class="text-red-400">Required</span> 
        }
        @if(control.errors?.['email']){
          <span class="text-red-400">please enter a valid email</span>
        }
        @if(control.errors?.['minlength']){
          <span class="text-red-400">Enter at least 5 characters</span>
        }
        <!-- @if(control.errors && !control.errors['required'] && !control.errors['email'] && !control.errors['minlength']){
          <span class="text-red-400">Something went wrong</span>
        } -->
      </div>
    } 
  </div>