  <div class="form_div">
    <label [for]="inputId">Enter Password</label>
    <div class="relative flex items-center">
      <input required class="w-full p-3 border rounded-md" placeholder="Password"
      [formControl]="control"
      [id]="inputId"
      [type]="hidden ? 'password' : 'text'" required />
     
      <i (click)="toggleVisibility()" class="absolute text-xl right-3 pi" [class.pi-eye]="hidden" [class.pi-eye-slash]="!hidden"></i>
    </div>
    @if(control.invalid && control.dirty && control.touched){
      <div class="flex flex-col items-start text-xs italic text-gray-600">
        <span class="text-red-400">Required</span>
        @if(control.errors?.['required']){
          <span class="text-red-400">please enter a valid password</span>
        }
      </div>
    }
  </div>