import { Component, Input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-password-field',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './password-field.component.html',
  styleUrl: './password-field.component.css'
})
export class PasswordFieldComponent {

  hidden : boolean = true;

toggleVisibility() {
  this.hidden = !this.hidden;
}
  @Input() placeholder : string = 'Enter your password';
  @Input() control : FormControl = new FormControl();
  @Input() inputId : string = '';
  @Input() submitted : boolean = false;
}
