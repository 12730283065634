import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardComponent } from '@components/dashboard/dashboard.component';
import { CardModule } from 'primeng/card';
import { MenubarModule } from 'primeng/menubar';
import { TableModule } from 'primeng/table';
import { Subject, first, takeUntil } from 'rxjs';
import { OutletMenubarComponent } from '../outlet-menubar/outlet-menubar.component';
import { Outlet, ResponseDTO } from '@models/index';
import { OutletService } from '@data-access/services';
@Component({
  selector: 'app-outlet',
  standalone: true,
  templateUrl: './outlet.component.html',
  styleUrl: './outlet.component.css',
  imports: [DashboardComponent, TableModule, CardModule, MenubarModule, OutletMenubarComponent]
})
export class OutletComponent implements OnInit, OnDestroy {

  outlet: Outlet | null = null;
  outletId: number = 0;
  name : string = 'smhos';
  private $destroy = new Subject<void>();

  constructor(private outletService: OutletService, private route: ActivatedRoute, private router: Router) {
  }


  ngOnInit() {
    this.route.params.subscribe(params => {
      this.outletId = +params['id'];
    //  console.log('Paramter in url', +params['id'])
    });
    this.outletService.outlet$.pipe(takeUntil(this.$destroy)).subscribe((result) => {
      
      if(!this.outlet === null || this.outlet?.id !== this.outletId){
        this.fetchOutlet(this.outletId);
      }
      else{
        this.outlet = result;
      }
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next();
    this.$destroy.complete();
  }

  menuNavigator(url: string) {
    this.router.navigate([url]);
  }

  public fetchOutlet(outlet: number) {
    this.outletService.getOutlet(outlet).pipe(first()).subscribe({
      next: (result: ResponseDTO) => {
        if (result.status) {
          this.outlet = result.data as Outlet;
          this.outletService.saveCurrentOutlet(this.outlet);
        }
        else {
          console.log("something went wrong");
        }
      },
      error: () => {
        console.log("Something went wrong");
      }
    });
  }

}
